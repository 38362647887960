import React, { useState, useEffect } from 'react'
import { Card, CardContent, Typography, Button } from '@material-ui/core';
import { darken } from '@material-ui/core/styles/colorManipulator';
import { FuseAnimate } from '@fuse';
import clsx from 'clsx';
import JWTLoginTab from './tabs/JWTLoginTab';

import { makeStyles } from '@material-ui/styles';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import EmployeeService from 'app/services/EmployeeService';
import { useDispatch } from "react-redux";
import * as Actions from "app/store/actions";
import ControlService from 'app/services/ControlService';

import { BrowserView, MobileView } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive'


const useStyles = makeStyles(theme => ({
    root: {
        background: 'linear-gradient(to right, ' + theme.palette.primary.dark + ' 0%, ' + darken(theme.palette.primary.dark, 0.5) + ' 100%)',
        color: theme.palette.primary.contrastText
    }
}));

function Login() {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 1224px)'
    })
    const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
    const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

    const script = document.createElement("script");
    script.src = "../auth/authConfig.js";
    script.async = true;
    script.onload = () => this.scriptLoaded();

    const dispatchMsg = useDispatch();
    const classes = useStyles();

    const [selectedTab, setSelectedTab] = useState(0);
    const [open, setOpen] = useState(false);
    const [email, handleEmail] = useState();
    const [logo, handleLogo] = useState([]);

    let correo = '';

    for (var i = 0; i < sessionStorage.length; i++) {
        var key = sessionStorage.key(i);

        try {
            var val = JSON.parse(sessionStorage.getItem(key));

        } catch {
            var val = {};
        }

        if (val.hasOwnProperty('username')) {
            correo = val['username'];
        }
    }

    function handleTabChange(event, value) {
        setSelectedTab(value);
    }

    function message(type = "null", message = "") {
        dispatchMsg(
            Actions.showMessage({
                message: message,
                autoHideDuration: 6000, //ms
                anchorOrigin: {
                    vertical: "top", //top bottom
                    horizontal: "center" //left center right
                },
                variant: type //success error info warning null
            })
        );
    }

    function handleClickOpen() {
        setOpen(true);
    }

    async function handleCancelClose() {
        setOpen(false);
    }

    async function fetchControl() {
        let control = await ControlService.getControl();
        handleLogo(control.data.data[0].logo)
    }

    useEffect(() => {
        fetchControl()
    }, []);

    async function handleClose() {
        setOpen(false);
        if (email != "") {
            //CHAMA API PARA SALVAR
            let resultSignature = await EmployeeService.forgotPassword(email);
            console.log(resultSignature)
            if (resultSignature.status === 200) {
                if (resultSignature.data.status == 'success') {
                    message("success", resultSignature.data.message);
                } else {
                    message("error", resultSignature.data.message);
                }

            } else {
                //ERRO
                message("error", resultSignature.data.message);
            }

            handleEmail("");
        }
    }

    function reloadPage() {
        window.location.reload(true);
        return false;
    }

    return (
        <div className={clsx(classes.root, "flex flex-col flex-1 flex-shrink-0 p-24 md:flex-row md:p-0")}>


            {isDesktopOrLaptop &&
                <div style={{ backgroundImage: 'url(assets/images/logos/talento_100.png)', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }}
                    className="flex flex-col flex-grow-0 items-center text-white p-16 text-center md:p-12 md:items-start md:flex-shrink-0 md:flex-1 ">
                    <img className="mb-32" style={{ width: '40rem', marginLeft: "30%", marginTop: "10%" }} src={process.env.REACT_APP_API_HOST + '/' + logo} alt="logo" />
                </div>
            }

            {isTabletOrMobile &&
                <div style={{ backgroundImage: 'url(assets/images/logos/talento_100.png)', backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat' }}
                className="flex flex-col flex-grow-0 items-center text-white p-16 text-center md:p-12 md:items-start md:flex-shrink-0 md:flex-1 ">
                    <img className="mb-32" style={{ width: '100%', marginLeft: "0", marginTop: "0" }} src={process.env.REACT_APP_API_HOST + '/' + logo} alt="logo" />
                </div>
            }

            <FuseAnimate animation={{ translateX: [0, '100%'] }}>

                <Card className="w-full max-w-400 mx-auto m-16 md:m-0" square>

                    <CardContent className="flex flex-col items-center justify-center p-32 md:p-48 md:pt-128 ">

                        <Typography variant="h6" className="text-center md:w-full mb-48">INGRESE A SU CUENTA</Typography>

                        {selectedTab === 0 && <JWTLoginTab correo={correo} />}

                        <span style={{ marginTop: '10px', cursor: 'pointer' }} onClick={handleClickOpen}>Olvidé mi contraseña</span>
                        <span style={{ marginTop: '10px', cursor: 'pointer' }} onClick={reloadPage}>Refrescar Aplicación</span>

                        {<div className="flex flex-col items-center justify-center pt-32">
                            <span className="font-medium">En caso de tener problemas con el acceso a la aplicación, por favor pulse sobre "Refrescar Aplicación" para actualizar su Navegador</span>
                        </div>}

                    </CardContent>
                </Card>
            </FuseAnimate>

            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Cambio de contraseña</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Por favor, ingrese su dirección de correo para solicitar una nueva contraeña. Una contraseña temporal será enviada al correo indicado a continuación.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="email"
                        label="Dirección de correo"
                        value={email}
                        onChange={e => handleEmail(e.target.value)}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelClose} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Solicitar Cambio de Contraseña
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Login;
